<template>
    <div class="invite-page">
        <div v-if="isBeOverdue == 0">
            <div class="content">
                <span class="el-icon-warning larger-icon" > </span>
                <p class="username">二维码已过期</p>
                <p class="invitation">请联系room创建者获取最新的二维码</p>
            </div>
        </div>

        <div v-if="isBeOverdue == 1">
            <!-- 添加切换账号按钮 -->
            <div class="switch-account-btn" @click="switchAccount">切换账号</div>
            <div v-if="joinShow == false">
                <div class="content">
                    <img style="width: 60px;height: 60px;" :src="roomInfo.cover">
                    <p class="username">你的同事</p>
                    <p class="invitation">邀请你加入「{{roomInfo.name}}」</p>
                    <el-input v-model="password" placeholder="请输入Room口令" class="password-input"></el-input>
                    <el-button type="primary" @click="joinNow">立即加入</el-button>
                </div>
                <el-dialog :visible.sync="showDialog" width="90%" custom-class="login-dialog" :before-close="closeDialog">
                    <loginDialog @userListChanged="userListChanged" ref="childRef" />
                </el-dialog>
                <el-dialog :visible.sync="selectDialog" width="90%" custom-class="login-dialog" :close-on-click-modal="false" >
                    <selectAccount @closeAccounts="closeAccounts" :accounts="accounts" />
                </el-dialog>
                <el-dialog :visible.sync="realDialog" width="90%" custom-class="login-dialog" :close-on-click-modal="false" :show-close="false" >
                    <realNameAuth @closeAuth="realDialog = false" />
                </el-dialog>
            </div>
            <div v-if="joinShow == true">
                <joinSuccess :roomInfo="roomInfo" />
            </div>
        </div>
    </div>
</template>

<script>
import loginDialog from "@/components/login/loginDialog.vue";
import selectAccount from "@/components/login/selectAccount.vue";
import joinSuccess from "@/components/room/joinSuccess.vue";
import realNameAuth from "@/components/comon/realNameAuth.vue";
import { wexinShare } from "@/assets/common/wecharshare";
import Vue from "vue";

export default {
    components: {
        loginDialog,
        selectAccount,
        joinSuccess,
        realNameAuth,
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        is_login() {
            return this.$store.state.is_login;
        }
    },
    data() {
        return {
            roomId: this.$route.query.state,
            showDialog: false,
            selectDialog: false,
            realDialog: false,
            password: '',
            accounts: [],
            roomInfo: {
                name: '',
                cover: 'https://oss.baigongbao.com/ico/head_ico.png'
            },
            joinShow: false,
            code: this.$route.query.code,
            isBeOverdue:-1,
        };
    },
    mounted() {
        var that = this;
        setTimeout(function (){
            //是否为微信客户端
            if (that.isWeChat()) {
                that.weixin();
                if (that.is_login == 0) {
                    if (typeof that.code == 'undefined') {
                        that.isBeOverdue = 1;
                        that.showDialog = true;
                    }
                    that.getWxLogin();
                }else {
                    that.getRoomInfo();
                }
            }else{
                if (that.is_login == 0) {
                    console.log(1546)
                    that.isBeOverdue = 1;
                    that.showDialog = true;
                }else{
                    console.log(565)
                    that.getRoomInfo();
                }
            }
        },1000)
    },
    methods: {
        closeDialog() {
            this.showDialog = false;
            this.$refs.childRef.clearForm();
        },

        userListChanged(value) {
            // 登录成功后，关闭登录弹窗，显示选择账号弹窗
            if (Array.isArray(value)) {
                if (value.length > 1) {
                    this.showDialog = false;
                    this.selectDialog = true;
                    this.accounts = value;
                } else {
                    localStorage.token = value[0].token;
                    localStorage.uuid = value[0].uuid;
                    this.loginResh(value[0].uuid);
                }
            } else {
                localStorage.token = value.token;
                localStorage.uuid = value.uuid;
                this.loginResh(value.uuid);
            }
        },

        loginResh(uuid) {
            this.$store.commit("get_user_info", () => {
                this.showDialog = false;
                this.getRoomInfo();
                this.utils.sus("登录成功");
                this.newApi.updateLoginTime({uuid: uuid});
            });
        },

        closeAccounts(value) {
            this.selectDialog = value;
            this.getRoomInfo();
        },

        getWxLogin() {
            if (typeof this.code != 'undefined') {
                Vue.prototype.openLoading("请稍后...");
                this.newApi.getMWxOpenId({code: this.code}).then((res) => {
                    if (res.isSuccess == 1) {
                        if (res.data.code == 0) {
                            this.utils.err(res.data.msg);
                            return false;
                        }
                        localStorage.token = res.data.token;
                        localStorage.uuid = res.data.uuid;
                        this.loginResh(res.data.uuid);
                    }
                })
            }
        },

        getRoomInfo() {
            var that = this;
            that.newApi.getRoomInfo({roomId: that.roomId}).then((res) => {
                if (res.isSuccess == 1) {
                    that.roomInfo = res.data;
                    if(new Date().getTime() > new Date(that.roomInfo.endTime).getTime()){
                        that.isBeOverdue = 0;
                    }else{
                        that.isBeOverdue = 1;
                    }
                }
            })
        },

        joinNow() {
            if (this.is_login == 0) {
                // 根据条件显示不同的el-dialog
                this.showDialog = true;
            } else {
                //项目room不需要实名认证
                if(this.roomInfo.roomType > 1){
                    if (this.user.realNameStatus != 2) {
                        this.realDialog = true;
                        return false;
                    }
                }
                if (this.password == '') {
                    this.utils.msg('请输入口令加入');
                    return false;
                } else {
                    //效验口令
                    this.newApi.passwordJoinRoom({roomId: this.roomId, password: this.password}).then((res) => {
                        if (res.isSuccess == 1) {
                            this.joinShow = true;
                        }
                    })
                }

            }
        },

        //切换账号
        switchAccount() {
            this.newApi.getLoginCodeList().then((res) => {
                if (res.isSuccess == 1) {
                    if (res.data.length > 1) {
                        this.selectDialog = true;
                        this.accounts = res.data;
                    } else {
                        this.utils.msg("当前只有一个账号，无法切换");
                    }
                }
            })
        },

        weixin() {
            var urls = window.location.href.split('#')[0];
            this.newApi.getWechatShare({url: urls}).then((res) => {
                var obj = {
                    appId: res.data.appId,
                    nonceStr: res.data.nonceStr,
                    signature: res.data.signature,
                    timestamp: res.data.timestamp,
                    jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
                };
                let shareData = {
                    title: "加入我的Room团队", // 分享标题
                    desc: "邀请加入我的Room团队，共同解锁高效协作", // 分享描述
                    link: urls,                // 分享链接
                    imgUrl: "https://oss.baigongbao.com/ico/team.png" // 分享图标
                };
                wexinShare(obj, shareData);
            })
        },

        //判断客户端
        isWeChat() {
            var ua = window.navigator.userAgent.toLowerCase();
            return /MicroMessenger/i.test(ua);
        },
    }
};
</script>

<style scoped>
.invite-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

/* 添加切换账号按钮样式 */
.switch-account-btn {
    position: absolute;
    top: 20px;
    right: 10px;
    font-size: 12px;
}

.content {
    text-align: center;
    margin-top: 50px;
}

.avatar {
    margin-bottom: 20px;
}

.username {
    font-weight: bold;
    margin: 10px 0;
}

.invitation {
    color: #666;
    margin-bottom: 20px;
}

.password-input {
    width: 300px;
    margin-bottom: 20px;
}
.larger-icon {
    font-size: 60px; /* 设置图标的大小为 30 像素 */
}

/* 全屏遮罩层样式 */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.overlay-content {
    text-align: center;
    color: #fff;
}
</style>
